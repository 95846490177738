var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("label", { staticClass: "label d-block" }, [
        _vm._v("\n\t\t" + _vm._s(_vm.rendLabel) + "\n\t"),
      ]),
      !_vm.inlineValidator
        ? _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("v-select", {
                staticClass: "w-100 grow-flex-auto border-radius-supplier",
                attrs: {
                  id: _vm.generateId,
                  placeholder: _vm.rendPlaceholder,
                  options: _vm.resultsListMap,
                  disabled: _vm.disabled,
                  filterable: false,
                  reduce: (option) => option.code,
                },
                on: {
                  input: _vm.handleCleared,
                  "option:selected": _vm.getDetailsOfLocation,
                  search: _vm.handleSearch,
                  close: _vm.handleClose,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n\t\t\t\t" +
                              _vm._s(_vm.FormMSG(2, "Please type a location")) +
                              "\n\t\t\t"
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3321790196
                ),
                model: {
                  value: _vm.locationModel,
                  callback: function ($$v) {
                    _vm.locationModel = $$v
                  },
                  expression: "locationModel",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-row icon-group-border-append",
                  attrs: { id: _vm.generateTarget },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "cursor-pointer d-inline-flex justify-content-center align-items-center",
                      staticStyle: { width: "34px" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn bg-transparent border-0",
                          attrs: {
                            size: "sm",
                            disabled: _vm.dataNotFound.length === 0,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.addLocation.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(_vm.getLucideIcon("PlusCircle"), {
                            tag: "component",
                            attrs: {
                              color: "#00A09C",
                              size: 18,
                              "stroke-width": 2.25,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _c("div", { staticClass: "v-select-validator-wrapper" }, [
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c("v-select", {
                  staticClass: "w-100 border-radius-supplier grow-flex-auto",
                  class: {
                    "is-invalid":
                      _vm.isSubmitted && _vm.$v.locationModel.$error,
                  },
                  attrs: {
                    id: _vm.generateId,
                    placeholder: _vm.rendPlaceholder,
                    options: _vm.resultsListMap,
                    disabled: _vm.disabled,
                    filterable: false,
                    reduce: (option) => option.code,
                  },
                  on: {
                    input: _vm.handleCleared,
                    "option:selected": _vm.getDetailsOfLocation,
                    search: _vm.handleSearch,
                    close: _vm.handleClose,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(2, "Please type a location")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.$v.locationModel.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.locationModel, "$model", $$v)
                    },
                    expression: "$v.locationModel.$model",
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "icon-group-border-append d-flex align-items-center",
                    staticStyle: { height: "32px" },
                    attrs: { id: _vm.generateTarget },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "cursor-pointer d-flex justify-content-center align-items-center",
                        staticStyle: { width: "34px" },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            ref: "add-location",
                            staticClass: "btn bg-transparent border-0",
                            attrs: {
                              size: "sm",
                              disabled: _vm.dataNotFound.length === 0,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.addLocation.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c(_vm.getLucideIcon("PlusCircle"), {
                              tag: "component",
                              attrs: {
                                color: "#00A09C",
                                size: 18,
                                "stroke-width": 2.25,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm.isSubmitted && _vm.$v.locationModel.$error
              ? _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v("\n\t\t\t" + _vm._s(_vm.errorMessage) + "\n\t\t"),
                ])
              : _vm._e(),
          ]),
      _c(
        "b-popover",
        {
          attrs: {
            placement: "top",
            show: _vm.showAlert,
            target: _vm.generateTarget,
            variant: "primary",
            triggers: "focus",
          },
          on: {
            "update:show": function ($event) {
              _vm.showAlert = $event
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.FormMSG(
                  3,
                  "If you cannot find your location, you can add it directly."
                )
              )
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }